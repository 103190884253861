'use client'

import { type BoxProps, Center, Loader, Stack, Text } from '@mantine/core'
import type { FC } from 'react'

type PageLoadingProps = BoxProps & {
	message: string
	color?: string
	textColor?: string
	loaderType?: string
}

const PageLoading: FC<PageLoadingProps> = ({
	message,
	color,
	textColor,
	loaderType,
	h,
	...rest
}) => {
	return (
		<Center my='auto' h={h || '100vh'} {...rest}>
			<Stack align='center'>
				<Loader color={color || 'yellow'} type={loaderType || 'dots'} />
				<Text
					variant={textColor ? 'default' : 'gradient'}
					c={textColor || undefined}
					gradient={
						!textColor
							? { from: 'rgb(18, 184, 134)', to: 'rgb(105, 219, 124)', deg: 90 }
							: undefined
					}
				>
					{message}
				</Text>
			</Stack>
		</Center>
	)
}

export default PageLoading
